import React, { Component } from 'react'
import MyD3 from './MyD3'

class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <MyD3 />
  }
}

export default App
